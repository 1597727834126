import '@ungap/custom-elements';
import '@naturehouse/design-system/components/protons/icon/Icon';
import '@naturehouse/design-system/components/atoms/anchor/Anchor';
import '@naturehouse/design-system/components/atoms/button/Button';
import '@naturehouse/design-system/components/atoms/chip/Chip';
import '@naturehouse/design-system/components/atoms/image/Image';
import '@naturehouse/design-system/components/atoms/input/Input';
import '@naturehouse/design-system/components/atoms/loader/Loader';
import '@naturehouse/design-system/components/atoms/navigation-list-item/NavigationListItem';
import '@naturehouse/design-system/components/atoms/notification/Notification';
import '@naturehouse/design-system/components/atoms/tool-tip/ToolTip';
import '@naturehouse/design-system/components/molecules/banner/Banner';
import '@naturehouse/design-system/components/molecules/curtain/Curtain';
import '@naturehouse/design-system/components/molecules/dialog/Dialog';
import '@naturehouse/design-system/components/molecules/form-field/FormField';
import '@naturehouse/design-system/components/molecules/input-incrementer/InputIncrementer';
import '@naturehouse/design-system/components/molecules/navigation-dropdown/NavigationDropdown';
import '@naturehouse/design-system/components/molecules/navigation-list/NavigationList';
import '@naturehouse/design-system/components/molecules/show-more/ShowMore';
import '@naturehouse/design-system/components/molecules/table/Table';
import '@naturehouse/design-system/components/molecules/toggle-icon-button/ToggleIconButton';
import '@naturehouse/design-system/components/organisms/login-form/LoginForm';
import '@naturehouse/design-system/components/organisms/navigation/Navigation';
import '@naturehouse/design-system/css/global.pcss';
import '@naturehouse/nh-essentials/lib/polyfills/toggleAttribute';
import ConvertReporter from '../../common/ConvertReporter';
import ErrorLogger from '../../common/ErrorLogger';
import OldCurtain from '../../ui/Curtain';
import '../../ui/web-components/modal/Modal';
import Module from '../module';
import ModalComponent from '../shared/components/ModalComponent';
import '../shared/components/Captcha';
import CookieConsentManager from './CookieConsentManager';
import './FormField';
import googleAnalyticsIdManager from './GoogleAnalyticsIdManager';
import LoginManager from './LoginManager';
import RedirectPopup from './RedirectPopup';
import DialogHandler from './components/DialogHandler';
import './components/NewsletterForm';
import './webComponents/AddFavoriteButton';
import './webComponents/CSRFInput';
import './webComponents/Carousel';
import './webComponents/MobileCurtainContainer';
import './webComponents/StickyCTA';
import '../../../css/app.pcss';

export default class AppModule extends Module {
    constructor() {
        super();

        ErrorLogger.init();

        document.addEventListener('DOMContentLoaded', async (): Promise<void> => {
            this.#loadComponents();
            this.#hamburgerMenuAnimation();

            const cookieConsentManager = new CookieConsentManager();
            await cookieConsentManager.initialize();

            const redirectPopup = new RedirectPopup();
            redirectPopup.initialize();

            LoginManager.getInstance();

            const languageSwitchDialogManager = new DialogHandler({
                buttonSelector: '[data-role="language-switch-dialog-toggle"]',
                dialogSelector: '[data-role="language-switch-dialog"]'
            });
            languageSwitchDialogManager.initialize();

            await googleAnalyticsIdManager.initialize();
            await ConvertReporter.track();
        });
    }

    #hamburgerMenuAnimation(): void {
        const element = document.querySelector('[data-role="hamburger-menu"]');
        if (!element) {
            return;
        }

        element.addEventListener('click', () => {
            element.classList.toggle('opened');
            element.setAttribute(
                'aria-expanded',
                element.classList.contains('opened') ? 'true' : 'false'
            );
        });
    }

    #loadComponents(): void {
        this.components.push(ModalComponent);
        this.components.push(OldCurtain);
        this.resolveComponents();
    }
}

(function appModuleInit(): void {
    const appModule = new AppModule();
    appModule.init();
})();
