import { Turnstile } from '@marsidev/react-turnstile';
import { getCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';
import { onIntersectionObserver } from '@naturehouse/nh-essentials/lib/events/eventHandling';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { DeploymentEnvironment } from '../../../util/featureToggle';

function renderCaptchaComponent(): void {
    const inputs = document.querySelectorAll<HTMLElement>('[data-role="cf-turnstile"]');
    const shouldBypassCaptcha =
        document.documentElement.dataset.deploymentEnv !== DeploymentEnvironment.PROD &&
        getCookieValue('captcha_bypass_token') !== null;

    if (shouldBypassCaptcha) {
        return;
    }

    inputs.forEach((input) => {
        const siteKey = input.getAttribute('data-sitekey');
        const responseFieldName = input.getAttribute('name') ?? undefined;
        const id = input.getAttribute('id') ?? undefined;
        const container = input.parentElement;

        if (!siteKey || !container) {
            return;
        }

        const renderTurnstile = (): void =>
            createRoot(container).render(
                <Turnstile
                    id={id}
                    className="cf-captcha"
                    siteKey={siteKey}
                    options={{
                        appearance: 'interaction-only',
                        feedbackEnabled: false,
                        responseFieldName
                    }}
                />
            );

        if ('IntersectionObserver' in window) {
            onIntersectionObserver(container, renderTurnstile, {
                root: null,
                rootMargin: '0px',
                threshold: 1.0
            });
            return;
        }

        renderTurnstile();
    });
}

document.addEventListener('DOMContentLoaded', renderCaptchaComponent);

export default renderCaptchaComponent;
